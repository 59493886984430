import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/contact.css'
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image
import foto6 from "../images/fotoscursos/foto6.jpg" // Tell webpack this JS file uses this image
import foto7 from "../images/fotoscursos/foto7.jpg" // Tell webpack this JS file uses this image
import foto8 from "../images/fotoscursos/foto8.jpg" // Tell webpack this JS file uses this image
import foto9 from "../images/fotoscursos/foto9.jpg" // Tell webpack this JS file uses this image
import foto10 from "../images/fotoscursos/foto10.jpg" // Tell webpack this JS file uses this image
import foto11 from "../images/fotoscursos/foto11.jpg" // Tell webpack this JS file uses this image
import foto12 from "../images/fotoscursos/foto12.jpg" // Tell webpack this JS file uses this image
import foto13 from "../images/fotoscursos/foto13.jpg" // Tell webpack this JS file uses this image
import foto14 from "../images/fotoscursos/foto14.jpg" // Tell webpack this JS file uses this image
import foto15 from "../images/fotoscursos/foto15.jpg" // Tell webpack this JS file uses this image
import fotoslider1 from "../images/about/1a.jpg" // Tell webpack this JS file uses this image
import fotoslider2 from "../images/about/2a.jpg" // Tell webpack this JS file uses this image
import fotoslider3 from "../images/about/3a.jpg" // Tell webpack this JS file uses this image
import fotoslider4 from "../images/about/4a.jpg" // Tell webpack this JS file uses this image
import fotoslider5 from "../images/about/5a.jpg" // Tell webpack this JS file uses this image
import fotoslider6 from "../images/about/6a.jpg" // Tell webpack this JS file uses this image
import fotoslider7 from "../images/about/7a.jpg" // Tell webpack this JS file uses this image
import fotoslider8 from "../images/about/8a.jpg" // Tell webpack this JS file uses this image
import fotoslider9 from "../images/about/9a.jpg" // Tell webpack this JS file uses this image
import fotoslider10 from "../images/about/10a.jpg" // Tell webpack this JS file uses this image
import fotoslider11 from "../images/about/11a.jpg" // Tell webpack this JS file uses this image

import Footerabout from "../components/footer-about"
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import sponsor1 from "../images/logos/about/logo1.png" // Tell webpack this JS file uses this image
import sponsor2 from "../images/logos/about/logo2.png" // Tell webpack this JS file uses this image
import sponsor3 from "../images/logos/about/logo3.png" // Tell webpack this JS file uses this image
import sponsor4 from "../images/logos/about/logo4.png" // Tell webpack this JS file uses this image
import sponsor5 from "../images/logos/about/logo5.png" // Tell webpack this JS file uses this image
import sponsor6 from "../images/logos/about/logo6.png" // Tell webpack this JS file uses this image
import sponsor7 from "../images/logos/about/logo7.png" // Tell webpack this JS file uses this image
import marker from "../images/logos/about/logo-local.png" // Tell webpack this JS file uses this image

import GoogleMap from '../components/GoogleMap'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};


const wqmatadouros = () => (
  <Layout>


    <div id="contact-section-page">
      <div className="colleft">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Contactos
          </div>
          <div className="text inter-regular center">
          Necessita de esclarecer alguma dúvida? <br></br> Verifique os nossos meios de contacto disponíveis e entre em contacto connosco. 
            </div>
      </div>

      </div>
   

    </div>
  
   

    <section id="googlemap">
      <div className="wrapper-map-contact">
        <div className="colmapa-contact">
        <GoogleMap />

        </div>
        <div className="colmapa-contact">
            <div className="wrapper">
            <img src={marker} alt="" />

              <p className="inter-bold">Localização dos Cursos</p>
              <p>R. Dr. Carlos Nunes Ferreira 80 <br></br>2305-101 Asseiceira, Tomar<br></br>Portugal</p>
              <a href="tel:+351914128826"><p>+351 914 128 826 </p></a>(Chamada para rede móvel nacional)

            </div>
        </div>
      </div>
    </section>
  

  

    <div id="section-fotos-about">
    
    </div>
    <div id="contact-section-about">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div>
    <Footerabout/>

  </Layout>
)

export default wqmatadouros
